import "./ContentPage.scss";

import React from "react";
import { graphql } from "gatsby";
import { RedocStandalone } from 'redoc';

import RedocTemplate from 'src/templates/RedocTemplate';

import { SwaggerRedocStandaloneTheme } from './styled-components/document-page-styled-components';

class SwaggerStandalonePage extends React.Component {
  render(){
    let {
      site: {
        pathPrefix,
        siteMetadata: {
          sitemap,
        },
      },
      openApi: {
        fields: {
          contentTitle,
          headTagTitle,
          isCollection,
          isTagged,
          organization: orgName,
          originalKey: pathKey,
          pathData,
          repository: repoName,
          tagsList,
          latestDDVersion,
          importedSummary,
          hasPrimaryNavigation,
          primaryNavData,
          currentVersion,
          currentDDVersion,
          summaryJson,
          rawJsonContent,
          canonicalVersion,
        }
      }
    } = this.props.data;
    const hidesVersion = null;
    const pathInfo = JSON.parse(pathData);
    const summary = JSON.parse(summaryJson);
    const home = [{"text":"Home","path":"/"}];
    const primaryNavigation = (hasPrimaryNavigation) ? JSON.parse(primaryNavData) : [];
    let mainNavData = [...home, ...primaryNavigation];

    let baseSpec = JSON.parse(rawJsonContent);
    const information = baseSpec.info;

    if (pathKey === '/') pathKey = "";

/*     if (!baseSpec["info"]["x-logo"]) {
      baseSpec["info"]["x-logo"] = {
        // The URL must be an absolute link
        "url": "https://i.imgur.com/7sBG3K8.png",
        "backgroundColor": "#111a44",
        "altText": "EOSIO",
        "href": "https://eos.io"
      }
    } */

    return (
      <RedocTemplate
        orgName={orgName}
        repoName={repoName}
        contentTitle={contentTitle}
        currentVersion={currentVersion}
        hidesVersion={hidesVersion}
        showSearch={false}
        isTagged={isTagged}
        title={headTagTitle}
        summary={summary}
        pathData={pathInfo}
        currentDDVersion={currentDDVersion}
        latestDDVersion={latestDDVersion}
        isCollection={isCollection}
        hasPrimaryNavigation={hasPrimaryNavigation}
        primaryNavData={mainNavData}
        tagsList={tagsList}
        importedSummary={importedSummary}
        pathKey={pathKey}
        pathPrefix={pathPrefix}
        information={information}
        canonicalVersion={canonicalVersion}
        minimap={sitemap}
        showMenuLinks={true}
      >
        <div className="redoc-standalone-container">
          <RedocStandalone spec={baseSpec} 
            options={{
              disableSearch: true,
              hideLoading: true,
              expandResponses: "200",
              nativeScrollbars: true,
              noAutoAuth: true,
              scrollYOffset: 60,
              theme: SwaggerRedocStandaloneTheme,
            }}/>
        </div>
      </RedocTemplate>
    )
  }
}

export default SwaggerStandalonePage

export const query = graphql`
    query($slug: String!) {
        site {
          pathPrefix
          siteMetadata {
            sitemap {
              key,
              org,
              repo,
              root,
            }
          }
        }
        openApi(fields: { slug: { eq: $slug } }) {
          fields {
            articleTitle
            contentTitle
            headTagTitle
            internalSummary
            isCollection
            isRoot
            isTagged
            organization
            originalKey
            pathData
            repository
            showSearch
            slug
            tagsList
            latestDDVersion
            importedSummary
            hasPrimaryNavigation
            primaryNavData
            currentVersion
            currentDDVersion
            canonicalVersion
            summaryJson
            rawJsonContent
          }
        }
    }
`
