import "./FullTemplate.scss";

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

import React from "react";
import App from 'src/components/App';
import { RedocHeader } from 'src/components/Header';
import NewFooter from 'src/components/Footer';

const Wrapper = styled.div`
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0

  span.operation-type {
    font-size: 7px !important;
  }

  ${MEDIA_QUERIES.TABLET} {
    padding: 0;
  }

  ${MEDIA_QUERIES.MOBILE} {
    padding: 0;
  }
`;

export default (props) => {
  let {
    children, orgName, repoName, currentVersion, hidesVersion, showSearch,
    title, isTagged, isCollection, contentTitle, hasPrimaryNavigation, 
    primaryNavData, pathKey, information, canonicalVersion,
  } = props;
  return (
    <App contentTitle={contentTitle} isTagged={isTagged} information={information}
      canonicalVersion={canonicalVersion}
    {...{orgName, repoName, currentVersion, hidesVersion, showSearch, title }} >
    <div className="FullTemplate">
      <main>
        <Wrapper>
          <Container>
              <RedocHeader
                orgName={orgName}
                repoName={repoName}
                currentVersion={currentVersion}
                showSearch={showSearch}
                hasPrimaryNavigation={hasPrimaryNavigation}
                primaryNavData={primaryNavData}
                isTagged={isTagged}
                isCollection={isCollection}
                pathKey={pathKey}
              />
            {children}
          </Container>
        </Wrapper>
      </main>
    </div>
    <footer>
      <NewFooter />
    </footer>
  </App>
  )
}
